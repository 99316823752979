
import EmptyState from '@/components/EmptyState.vue';
import { EventScoreDto } from '@/models/Event';
import { PropType, defineComponent } from 'vue';
import HeadingComponent from '@/components/HeadingComponent.vue';
import router from '@/router';
import EventListItem from './EventListItem.vue';
export default defineComponent({
  components: { HeadingComponent, EmptyState, EventListItem },
  props: {
    events: {
      type: Array as PropType<EventScoreDto[]>,
      required: true,
    },
  },
  setup() {
    const handleEventClick = async (eventId: string) => {
      await router.push({
        name: 'follow-up-nutrition-event-detail',
        params: {
          eventId,
        },
      });
    };

    return {
      handleEventClick,
    };
  },
});
