
import MealChart from './MealChart.vue';
import Patient from '@/models/Patient';
import { eventService } from '@/services/EventService';
import { measurementService } from '@/services/MeasurementService';
import { ServiceError } from '@/services/util/ServiceError';
import { PropType, Ref, defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import EventsList from './EventsList.vue';
import { EventScoreDto, EventType } from '@/models/Event';
import LoadingComponent from '@/components/LoadingComponent.vue';

export default defineComponent({
  components: { MealChart, EventsList, LoadingComponent },
  props: {
    selectedStartDate: {
      type: String,
      required: true,
    },
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
    selectedEndDate: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const patientId = Array.isArray(route.params.patientId) ? route.params.patientId[0] : route.params.patientId;
    const loadingEvents = ref(false);
    const glucoseStatistics = ref();
    const heartRateStatistics = ref();
    const events: Ref<EventScoreDto[]> = ref([]);

    const fetchStatisticsGlucose = async () => {
      const result = await measurementService.findGlucoseStatistics(
        patientId,
        props.selectedStartDate,
        props.selectedEndDate,
      );
      if (!(result instanceof ServiceError)) {
        glucoseStatistics.value = result;
      }
    };
    const fetchStatisticsHeartRate = async () => {
      const result = await measurementService.findHeartRateStatistics(
        patientId,
        props.selectedStartDate,
        props.selectedEndDate,
      );
      if (!(result instanceof ServiceError)) {
        heartRateStatistics.value = result;
      }
    };

    const fetchEvents = async () => {
      const result = await eventService.findByMedicalTestAndPatientId(
        '',
        patientId,
        true,
        props.selectedStartDate,
        props.selectedEndDate,
      );
      if (!(result instanceof ServiceError)) {
        events.value = result.filter((event) => event.type === EventType.MEAL);
      }
    };
    const fecthStatistics = async () => {
      loadingEvents.value = true;
      heartRateStatistics.value = undefined;
      glucoseStatistics.value = undefined;
      events.value = [];
      fetchEvents();
      if (events.value) {
        await fetchStatisticsGlucose();
        await fetchStatisticsHeartRate();
      }
      loadingEvents.value = false;
    };
    watch(
      () => props.selectedStartDate,
      async () => {
        await fecthStatistics();
      },
    );

    onMounted(async () => {
      if (props.selectedStartDate) {
        fecthStatistics();
      }
    });

    return {
      loadingEvents,
      events,
      glucoseStatistics,
      heartRateStatistics,
    };
  },
});
