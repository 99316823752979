
import { PropType, Ref, computed, defineComponent, onMounted, ref, watch } from 'vue';
import { Diet, NutritionalPlan, DietDayFormDto } from '@/models/NutritionalPlan';
import { useProfileStore } from '@/store/profile.module';
import { nutritionalPlanService } from '@/services/NutritionalPlanService';
import { useRoute } from 'vue-router';
import { ServiceError } from '@/services/util/ServiceError';
import { updateDietDayForm, isUpdateDietDayFormValid } from '@/validation/nutritionalPlanForm';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import NutritionalPlanMenuCardBase from './NutritionalPlanMenuCardBase.vue';

export default defineComponent({
  components: { NutritionalPlanMenuCardBase },
  emits: ['on-edit-diet-day'],
  props: {
    diet: {
      type: Object as PropType<Diet>,
      required: true,
    },
    daySelectedIndex: {
      type: Number,
      required: false,
    },
    date: {
      type: String,
      required: false,
    },
    nutritionalPlanId: {
      type: String,
      required: false,
    },
    mealsBySelectedDay: {
      type: Array as PropType<any[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const route = useRoute();
    const storeProfile = useProfileStore();
    const toast = useToast();
    const { t } = useI18n();

    const patientId = route.params.patientId as string;

    const diet = ref(props.diet);
    const editMode = ref(false);
    const waitSubmit = ref(false);
    const showValidation = ref(false);
    const nutritionalPlanOptions: Ref<{ label: string; value: string }[]> = ref([]);

    const nutritionalPlanId = computed(() =>
      props.nutritionalPlanId
        ? props.nutritionalPlanId
        : diet.value.dietDays.find((d) => d.day === (props.daySelectedIndex ?? 0) + 1)?.nutritionalPlanId || '',
    );
    const isAdminUser = computed(() => storeProfile.isAdmin);
    const isReceptionistUser = computed(() => storeProfile.isReceptionist);
    const isSpecialistUser = computed(() => storeProfile.isSpecialist);
    const validatedDietDayForm = computed(() => updateDietDayForm(dietFormDto.value));
    const valid = computed(() => isUpdateDietDayFormValid(validatedDietDayForm.value));
    const nutritionalPlan: Ref<NutritionalPlan | undefined> = ref();
    const dietFormDto: Ref<DietDayFormDto> = ref(new DietDayFormDto(nutritionalPlanId.value));

    const onEditMenuDay = () => {
      editMode.value = !editMode.value;
    };

    const onUpdateNutritionalPlan = (nutritionalPlanId: string) => {
      dietFormDto.value.nutritionalPlanId = nutritionalPlanId;
    };

    const onSubmit = async () => {
      showValidation.value = true;
      if (!valid.value) {
        return;
      }
      if (dietFormDto.value) {
        try {
          waitSubmit.value = true;
          emit('on-edit-diet-day', dietFormDto.value, diet.value);
        } catch (error) {
          toast.add({
            severity: 'error',
            summary: `${t('messages.notifications.errorEditMenu')} ${t('messages.notifications.tryLater')}`,
            life: 3000,
          });
        } finally {
          waitSubmit.value = false;
          editMode.value = false;
        }
      }
    };

    const onCancelEdition = () => {
      editMode.value = false;
    };

    const fetchNutritionPlan = async () => {
      const result = await nutritionalPlanService.findById(patientId, nutritionalPlanId.value);
      if (!(result instanceof ServiceError)) {
        nutritionalPlan.value = result;
      }
    };

    const fetchNutritionPlanOptions = async () => {
      if (nutritionalPlanOptions.value.length > 0) {
        return;
      }
      const result = await nutritionalPlanService.findAll(patientId);
      if (!(result instanceof ServiceError)) {
        nutritionalPlanOptions.value = result
          .filter((plan) => plan.enabled)
          .map((plan) => ({ label: plan.name, value: plan.id }));
      }
    };

    function userCanEditDiet() {
      if (isAdminUser.value || isReceptionistUser.value) {
        return true;
      }

      if (!storeProfile.userId || !diet.value.professional || !isSpecialistUser.value) {
        return false;
      }

      return diet.value.professional.id === storeProfile.userId;
    }

    onMounted(() => {
      fetchNutritionPlan();
    });

    watch(editMode, async (isEdit) => {
      if (isEdit) {
        await fetchNutritionPlanOptions();
      }
    });

    watch([() => props.daySelectedIndex, () => props.date, () => props.nutritionalPlanId], async () => {
      await fetchNutritionPlan();
    });

    return {
      userCanEditDiet,
      nutritionalPlan,
      editMode,
      waitSubmit,
      onSubmit,
      onCancelEdition,
      dietFormDto,
      showValidation,
      nutritionalPlanOptions,
      validatedDietDayForm,
      valid,
      onEditMenuDay,
      onUpdateNutritionalPlan,
    };
  },
});
