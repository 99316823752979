
import HeadingComponent from '@/components/HeadingComponent.vue';
import { patientService } from '@/services/PatientService';
import { ServiceError } from '@/services/util/ServiceError';
import { Ref, defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import MenusTab from './tabs/MenusTab.vue';
import EventsTab from './tabs/EventsTab.vue';
import { nutritionalPlanService } from '@/services/NutritionalPlanService';
import { useToast } from 'primevue/usetoast';
import MenuPlanificationCreateFormModal from './MenuPlanificationCreateFormModal.vue';
import DaysPickerWithMonths from '@/components/wearables/common/DaysPickerWithMonths.vue';

export default defineComponent({
  components: {
    DaysPickerWithMonths,
    HeadingComponent,
    MenusTab,
    EventsTab,
    MenuPlanificationCreateFormModal,
  },

  setup() {
    const route = useRoute();
    const patientId = Array.isArray(route.params.patientId) ? route.params.patientId[0] : route.params.patientId;
    const tabSelected: Ref<'events' | 'menus'> = ref('menus');
    const patient = ref();
    const { t } = useI18n();
    const selectedStartDate = ref('');
    const selectedEndDate = ref('');
    const menuCreateModalRef = ref();
    const tabsMenu = ref([
      { label: t(`wearables.sleepTab.title`), to: 'sleep' },
      { label: t(`wearables.activityTab.title`), to: 'activity' },
      { label: t(`wearables.nutritionTab.title`), to: 'nutrition' },
    ]);

    const openMenuPlanificationDialog = async () => {
      menuCreateModalRef.value?.openDialog();
    };

    const onNewMenuSubmit = (submittingValue: boolean) => {
      submitting.value = submittingValue;
    };

    const getPatientData = async () => {
      const result = await patientService.find(patientId);
      if (!(result instanceof ServiceError)) {
        patient.value = result;
      }
    };

    const onDayPickerChanged = (dayStart: string, dayEnd: string) => {
      if (dayStart == selectedStartDate.value && dayEnd == selectedEndDate.value) {
        return;
      }
      selectedStartDate.value = dayStart;
      selectedEndDate.value = dayEnd;
    };

    onMounted(async () => {
      await getPatientData();
    });

    let childHaveMenu = ref(false);
    const toast = useToast();
    const showDialog = ref(false);
    const submitting = ref(false);
    const waitingForReport = ref(false);

    const onchildHaveMenu = (haveMenu: boolean) => {
      childHaveMenu.value = haveMenu;
    };

    const deleteMenuDay = async () => {
      try {
        submitting.value = true;
        const dietDayUpdated = await nutritionalPlanService.deleteMenuDay(patientId, selectedStartDate.value);
        if (dietDayUpdated instanceof ServiceError) {
          toast.add({
            severity: 'error',
            summary: `${t('messages.notifications.errorDeleteMenuDay')} ${t('messages.notifications.tryLater')}`,
            life: 3000,
          });
        } else {
          toast.add({
            severity: 'success',
            summary: `${t('messages.notifications.successDeleteMenuDay')}`,
            life: 3000,
          });
        }
        submitting.value = false;
      } catch (error) {
        submitting.value = false;
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorDeleteMenuDay')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } finally {
        showDialog.value = false;
      }
    };

    const downloadPlanification = async () => {
      waitingForReport.value = true;
      const result = await nutritionalPlanService.getPdfFile(patientId, selectedStartDate.value);
      waitingForReport.value = false;

      downloadFile(result.file);
    };

    const downloadFile = (fileBase64: string) => {
      const a = document.createElement('a'); //Create <a>
      a.href = 'data:application/pdf;base64,' + fileBase64; //File Base64 Goes here
      a.download = 'Menu.pdf'; //File name Here
      a.click(); //Downloaded file
    };

    return {
      onDayPickerChanged,
      patient,
      tabsMenu,
      openMenuPlanificationDialog,
      menuCreateModalRef,
      route,
      tabSelected,
      selectedStartDate,
      selectedEndDate,
      showDialog,
      submitting,
      waitingForReport,
      deleteMenuDay,
      onNewMenuSubmit,
      childHaveMenu,
      onchildHaveMenu,
      downloadPlanification,
    };
  },
});
