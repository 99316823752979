
import EmptyState from '@/components/EmptyState.vue';
import HeadingComponent from '@/components/HeadingComponent.vue';
import MultiChart from '@/components/wearables/common/MultiChart.vue';
import { EventScoreDto, EventType } from '@/models/Event';
import { Chart, ChartType } from '@/models/Statistics';
import { computed, defineComponent, PropType } from 'vue';
export default defineComponent({
  components: { MultiChart, HeadingComponent, EmptyState },
  props: {
    chartStart: {
      type: String,
      required: true,
    },
    chartEnd: {
      type: String,
      required: true,
    },
    glucoseStatistics: {
      type: Object,
    },
    heartRateStatistics: {
      type: Object,
    },
    events: {
      type: Object as PropType<EventScoreDto[]>,
    },
    title: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const chartId = 'meal-glucose-hr-chart';

    const glucoseSerie = computed(
      () => props.glucoseStatistics?.charts?.find((chart: Chart) => chart.type == ChartType.CGM_DAILY)?.series[0],
    );
    const heartRateSerie = computed(
      () => props.heartRateStatistics?.charts?.find((chart: Chart) => chart.type == ChartType.HEART_RATE)?.series[0],
    );

    const noDataGlusoce = computed(() => {
      return glucoseSerie.value?.data && glucoseSerie.value.data.length == 0;
    });

    const noDataHr = computed(() => {
      return heartRateSerie.value?.data && heartRateSerie.value.data.length == 0;
    });

    const showEmptyState = computed(() => {
      return noDataGlusoce.value && noDataHr.value;
    });

    const eventsFiltered = computed(() => props.events?.filter((event: EventScoreDto) => event.type == EventType.MEAL));

    return {
      showEmptyState,
      glucoseSerie,
      heartRateSerie,
      chartId,

      noDataGlusoce,
      noDataHr,
      eventsFiltered,
    };
  },
});
