import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "l-container" }
const _hoisted_2 = { class: "l-row--valign days_picker" }
const _hoisted_3 = { class: "sub-heading-container" }
const _hoisted_4 = { class: "nutrition-tabs-container" }
const _hoisted_5 = { class: "menu__cancel-test-cta-p" }
const _hoisted_6 = {
  key: 0,
  class: "p-grid--wearables-nutrition"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_DaysPickerWithMonths = _resolveComponent("DaysPickerWithMonths")!
  const _component_MenusTab = _resolveComponent("MenusTab")!
  const _component_EventsTab = _resolveComponent("EventsTab")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_MenuPlanificationCreateFormModal = _resolveComponent("MenuPlanificationCreateFormModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeadingComponent, {
      title: _ctx.$t(_ctx.route.meta.breadcrumb ? _ctx.route.meta.breadcrumb[0].label : ''),
      "heading-icon": "icon-heart",
      tabs: _ctx.tabsMenu
    }, {
      right: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button p-button-icon p-button-secondary",
          disabled: _ctx.waitingForReport,
          onClick: _ctx.downloadPlanification
        }, {
          default: _withCtx(() => [
            _cache[5] || (_cache[5] = _createElementVNode("i", {
              class: "icon-download",
              "aria-hidden": "true"
            }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('wearables.nutritionTab.download-planification')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"]),
        _createVNode(_component_Button, {
          class: "p-button p-button-icon",
          onClick: _ctx.openMenuPlanificationDialog
        }, {
          default: _withCtx(() => [
            _cache[6] || (_cache[6] = _createElementVNode("i", {
              class: "icon-plus",
              "aria-hidden": "true"
            }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('wearables.nutritionTab.add-planification')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["title", "tabs"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.patient)
          ? (_openBlock(), _createBlock(_component_DaysPickerWithMonths, {
              key: 0,
              "initial-day": _ctx.patient.creationDate,
              "week-or-days": "week",
              onOnDayPickerChanged: _ctx.onDayPickerChanged
            }, null, 8, ["initial-day", "onOnDayPickerChanged"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: _normalizeClass(["nutrition-tab", { 'nutrition-tab-active': _ctx.tabSelected === 'menus' }])
          }, [
            _createElementVNode("span", {
              class: "nutrition-tab__button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.tabSelected = 'menus'))
            }, _toDisplayString(_ctx.$t('wearables.nutritionTab.tabs.menus')), 1)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["nutrition-tab", { 'nutrition-tab-active': _ctx.tabSelected === 'events' }])
          }, [
            _createElementVNode("span", {
              class: "nutrition-tab__button",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.tabSelected = 'events'))
            }, _toDisplayString(_ctx.$t('wearables.nutritionTab.tabs.events')), 1)
          ], 2)
        ]),
        (_ctx.tabSelected === 'menus' && _ctx.childHaveMenu)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "menu__cancel-test-cta",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDialog = true))
            }, [
              _cache[7] || (_cache[7] = _createElementVNode("i", {
                class: "icon-trash_full menu__cancel-test-cta-icon",
                "aria-hidden": "true"
              }, null, -1)),
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('nutrition.menu.modal-delete-header')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      (!_ctx.submitting)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_ctx.tabSelected === 'menus')
              ? (_openBlock(), _createBlock(_component_MenusTab, {
                  key: 0,
                  date: _ctx.selectedStartDate,
                  onOnChildHaveMenu: _ctx.onchildHaveMenu
                }, null, 8, ["date", "onOnChildHaveMenu"]))
              : _createCommentVNode("", true),
            (_ctx.tabSelected === 'events')
              ? (_openBlock(), _createBlock(_component_EventsTab, {
                  key: 1,
                  "selected-start-date": _ctx.selectedStartDate,
                  "selected-end-date": _ctx.selectedEndDate,
                  patient: _ctx.patient
                }, null, 8, ["selected-start-date", "selected-end-date", "patient"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showDialog,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showDialog) = $event)),
      header: _ctx.$t('nutrition.menu.modal-delete-header'),
      modal: true,
      class: "p-fluid"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('common.get-back'),
          class: "p-button-secondary p-button-medium",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showDialog = false))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('common.delete'),
          class: "p-button p-button-medium p-button-secondary cancel-test-confirm-button p-button-danger",
          loading: _ctx.submitting,
          onClick: _ctx.deleteMenuDay
        }, null, 8, ["label", "loading", "onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.menu.modal-delete-title')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.menu.modal-delete-message')), 1)
      ]),
      _: 1
    }, 8, ["visible", "header"]),
    _createVNode(_component_MenuPlanificationCreateFormModal, {
      ref: "menuCreateModalRef",
      onNewMenuSubmit: _ctx.onNewMenuSubmit
    }, null, 8, ["onNewMenuSubmit"])
  ], 64))
}