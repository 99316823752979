import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = {
  key: 0,
  class: "nutritional-plan-menu-info"
}
const _hoisted_4 = {
  key: 0,
  class: "nutritional-plan-name"
}
const _hoisted_5 = { class: "nutritional-plan-planned-card" }
const _hoisted_6 = { class: "nutritional-plan-planned-totals" }
const _hoisted_7 = { class: "nutritional-plan-info" }
const _hoisted_8 = { class: "info-item" }
const _hoisted_9 = { class: "info-item" }
const _hoisted_10 = { class: "info-item" }
const _hoisted_11 = { class: "info-item" }
const _hoisted_12 = { class: "nutritional-plan-kcal" }
const _hoisted_13 = { class: "nutritional-plan-real-card" }
const _hoisted_14 = { class: "nutritional-plan-real-totals" }
const _hoisted_15 = { class: "nutritional-plan-info" }
const _hoisted_16 = { class: "info-item" }
const _hoisted_17 = { class: "info-item" }
const _hoisted_18 = { class: "info-item" }
const _hoisted_19 = { class: "info-item" }
const _hoisted_20 = { class: "nutritional-plan-kcal" }
const _hoisted_21 = {
  key: 1,
  class: "card-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeadingComponent, {
      title: _ctx.$t('nutrition.menu.nutritional-plan-card-title'),
      divider: false,
      "sub-heading": true
    }, {
      right: _withCtx(() => [
        _renderSlot(_ctx.$slots, "headingActions", {}, () => [
          _withDirectives((_openBlock(), _createBlock(_component_Button, {
            disabled: !_ctx.userCanEditDiet,
            class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", _ctx.editMode ? 'p-disabled' : '']),
            onClick: _ctx.updateEditMode
          }, {
            default: _withCtx(() => [
              _createElementVNode("i", {
                class: "icon-edit",
                "aria-label": _ctx.$t('common.edit'),
                "aria-hidden": "true"
              }, null, 8, _hoisted_2)
            ]),
            _: 1
          }, 8, ["disabled", "class", "onClick"])), [
            [
              _directive_tooltip,
              _ctx.$t('common.edit'),
              void 0,
              { bottom: true }
            ]
          ])
        ], true)
      ]),
      _: 3
    }, 8, ["title"]),
    (_ctx.nutritionalPlan)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (!_ctx.editMode)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _cache[1] || (_cache[1] = _createElementVNode("i", {
                  class: "icon-circle_check_outline",
                  "aria-hidden": "true"
                }, null, -1)),
                _createElementVNode("h4", null, _toDisplayString(_ctx.nutritionalPlan.name), 1)
              ]))
            : (_openBlock(), _createBlock(_component_Dropdown, {
                key: 1,
                id: "nutritionalPlanType",
                modelValue: _ctx.nutritionalPlanId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nutritionalPlanId) = $event)),
                options: _ctx.nutritionalPlanOptions,
                "option-label": "label",
                "option-value": "value",
                class: _normalizeClass({
            'no-editable': !_ctx.editMode,
            'p-invalid': _ctx.showValidation && !_ctx.validatedDietDayForm.nutritionalPlanId.valid,
          }),
                onChange: _ctx.onChangeNutritionalPlan
              }, null, 8, ["modelValue", "options", "class", "onChange"])),
          (!_ctx.editMode)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('wearables.nutritionTab.planned-totals')), 1),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _cache[2] || (_cache[2] = _createElementVNode("i", {
                          class: "icon-proteins",
                          "aria-hidden": "true"
                        }, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(_ctx.plannedProteinBites || '--'), 1)
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _cache[3] || (_cache[3] = _createElementVNode("i", {
                          class: "icon-fats",
                          "aria-hidden": "true"
                        }, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(_ctx.plannedFatsBites || '--'), 1)
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _cache[4] || (_cache[4] = _createElementVNode("i", {
                          class: "icon-hc",
                          "aria-hidden": "true"
                        }, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(_ctx.plannedCarbohydratesBites || '--'), 1)
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _cache[5] || (_cache[5] = _createElementVNode("i", {
                          class: "icon-meals",
                          "aria-hidden": "true"
                        }, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(_ctx.totalMeals || '--'), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("p", _hoisted_12, [
                    _createTextVNode(_toDisplayString(_ctx.$t('wearables.nutritionTab.total-kcal-day')) + ": ", 1),
                    _createElementVNode("span", null, _toDisplayString(`${_ctx.plannedFoodCalories} ${_ctx.$t('nutrition.foodDistribution.kcal')}`), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('wearables.nutritionTab.actual-totals')), 1),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _cache[6] || (_cache[6] = _createElementVNode("i", {
                          class: "icon-proteins",
                          "aria-hidden": "true"
                        }, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(_ctx.actualProteinBites || '--'), 1)
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _cache[7] || (_cache[7] = _createElementVNode("i", {
                          class: "icon-fats",
                          "aria-hidden": "true"
                        }, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(_ctx.actualFatsBites || '--'), 1)
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        _cache[8] || (_cache[8] = _createElementVNode("i", {
                          class: "icon-hc",
                          "aria-hidden": "true"
                        }, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(_ctx.actualCarbohydratesBites || '--'), 1)
                      ]),
                      _createElementVNode("div", _hoisted_19, [
                        _cache[9] || (_cache[9] = _createElementVNode("i", {
                          class: "icon-meals",
                          "aria-hidden": "true"
                        }, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(_ctx.totalMeals || '--'), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("p", _hoisted_20, [
                    _createTextVNode(_toDisplayString(_ctx.$t('wearables.nutritionTab.total-kcal-day')) + ": ", 1),
                    _createElementVNode("span", null, _toDisplayString(`${_ctx.actualKcalTotal} ${_ctx.$t('nutrition.foodDistribution.kcal')}`), 1)
                  ])
                ])
              ], 64))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.editMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
          _createVNode(_component_Button, {
            class: "p-button-secondary p-button-medium",
            label: _ctx.$t('common.cancel'),
            onClick: _ctx.onCancelEdition
          }, null, 8, ["label", "onClick"]),
          _createVNode(_component_Button, {
            class: "p-button p-button-info p-button-medium",
            loading: _ctx.waitSubmit,
            label: _ctx.$t('common.refresh'),
            onClick: _ctx.onSubmit
          }, null, 8, ["loading", "label", "onClick"])
        ]))
      : _createCommentVNode("", true)
  ]))
}