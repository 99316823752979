import {
  DietDayFormDto,
  MenuDayCreateFormDto,
  NutritionalPlanCreateFormDto,
  NutritionalPlanFormDto,
} from '@/models/NutritionalPlan';
import { ValidationResult, isObjectValid, required } from './form';

export interface CreateNutritionalPlanFormValidity {
  name: ValidationResult;
}

export interface CreateMenuDayFormValidity {
  startDate: ValidationResult;
  weeks: ValidationResult;
}

export interface UpdateNutritionalPlanFormValidity {
  gender: ValidationResult;
  dietType: ValidationResult;
  weight: ValidationResult;
  height: ValidationResult;
  currentCalories: ValidationResult;
  birthdate: ValidationResult;
  activityFrequency: ValidationResult;
  numberOfMeals: ValidationResult;
}

export function isCreateNutritionalPlanFormValid(form: CreateNutritionalPlanFormValidity) {
  return isObjectValid<CreateNutritionalPlanFormValidity>(form);
}

export function isCreateMenuDayFormValid(form: CreateMenuDayFormValidity) {
  return isObjectValid<CreateMenuDayFormValidity>(form);
}

export function createNutritionalPlanForm(
  nutritionalPlanCreateFormDto: NutritionalPlanCreateFormDto,
): CreateNutritionalPlanFormValidity {
  const name = required(nutritionalPlanCreateFormDto.name);

  return {
    name,
  };
}

export function createMenuDayForm(nutritionalPlanCreateFormDto: MenuDayCreateFormDto): CreateMenuDayFormValidity {
  const startDate = required(nutritionalPlanCreateFormDto.startDate);
  const weeks = required(nutritionalPlanCreateFormDto.weeks);

  return {
    startDate,
    weeks,
  };
}

export function isUpdateNutritionalPlanFormValid(form: UpdateNutritionalPlanFormValidity) {
  return isObjectValid<UpdateNutritionalPlanFormValidity>(form);
}

export function updateNutritionalPlanForm(
  nutritionalPlanFormDto: NutritionalPlanFormDto,
): UpdateNutritionalPlanFormValidity {
  const gender = required(nutritionalPlanFormDto.gender);
  const dietType = required(nutritionalPlanFormDto.dietType);
  const weight = required(nutritionalPlanFormDto.weight);
  const height = required(nutritionalPlanFormDto.height);
  const currentCalories = required(nutritionalPlanFormDto.currentCalories);
  const birthdate = required(nutritionalPlanFormDto.birthdate);
  const activityFrequency = required(nutritionalPlanFormDto.activityFrequency);
  const numberOfMeals = required(nutritionalPlanFormDto.numberOfMeals);

  return {
    gender,
    dietType,
    weight,
    height,
    currentCalories,
    birthdate,
    activityFrequency,
    numberOfMeals,
  };
}

export interface UpdateDietDayFormValidity {
  nutritionalPlanId: ValidationResult;
}

export function isUpdateDietDayFormValid(form: UpdateDietDayFormValidity) {
  return isObjectValid<UpdateDietDayFormValidity>(form);
}

export function updateDietDayForm(nutritionalPlanCreateFormDto: DietDayFormDto): UpdateDietDayFormValidity {
  const nutritionalPlanId = required(nutritionalPlanCreateFormDto.nutritionalPlanId);

  return {
    nutritionalPlanId,
  };
}
