
import HeadingComponent from '@/components/HeadingComponent.vue';
import { DietDayFormDto, MenuMealDetailDto, MenuMealDto, NutritionalPlan } from '@/models/NutritionalPlan';
import { UpdateDietDayFormValidity } from '@/validation/nutritionalPlanForm';
import { DropdownChangeEvent } from 'primevue/dropdown';
import { PropType, computed, defineComponent } from 'vue';

export default defineComponent({
  components: { HeadingComponent },
  emits: ['on-edit-diet-day', 'on-update-edit-mode', 'on-update-nutritional-plan'],
  props: {
    editMode: {
      type: Boolean,
      required: true,
    },
    waitSubmit: {
      type: Boolean,
      required: true,
    },
    onSubmit: {
      type: Function as PropType<(payload: MouseEvent) => void>,
      required: true,
    },
    onCancelEdition: {
      type: Function as PropType<(payload: MouseEvent) => void>,
      required: true,
    },
    totalMeals: {
      type: Number,
      required: false,
    },
    nutritionalPlan: {
      type: Object as PropType<NutritionalPlan>,
      required: false,
    },
    nutritionalPlanOptions: {
      type: Array,
      required: true,
    },
    userCanEditDiet: {
      type: Boolean,
      required: true,
    },
    showValidation: {
      type: Boolean,
      required: true,
    },
    dietFormDto: {
      type: Object as PropType<DietDayFormDto>,
      required: true,
    },
    validatedDietDayForm: {
      type: Object as PropType<UpdateDietDayFormValidity>,
      required: true,
    },
    mealsBySelectedDay: {
      type: Array as PropType<any[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const nutritionalPlanId = computed(() => props.dietFormDto.nutritionalPlanId);
    const actualProteinBites = computed(() => {
      let total = 0;
      props.mealsBySelectedDay.forEach((meal: MenuMealDto) => {
        total += meal.mealDetails.reduce((total: number, row: any) => {
          return total + parseFloat(row.food.proteinBites) * row.bites || 0;
        }, 0);
      });
      return total;
    });

    const plannedCarbohydratesBites = computed(() => {
      let total = 0;
      props.mealsBySelectedDay.forEach((meal: MenuMealDto) => {
        if (meal.carbohydratesBites) {
          total += meal.carbohydratesBites;
        }
      });
      return total;
    });

    const plannedFatsBites = computed(() => {
      let total = 0;
      props.mealsBySelectedDay.forEach((meal: MenuMealDto) => {
        if (meal.fatBites) {
          total += meal.fatBites;
        }
      });
      return total;
    });

    const plannedProteinBites = computed(() => {
      let total = 0;
      props.mealsBySelectedDay.forEach((meal: MenuMealDto) => {
        if (meal.proteinBites) {
          total += meal.proteinBites;
        }
      });
      return total;
    });

    const actualCarbohydratesBites = computed(() => {
      let total = 0;
      props.mealsBySelectedDay.forEach((meal: MenuMealDto) => {
        total += meal.mealDetails.reduce((total: number, row: any) => {
          return total + parseFloat(row.food.carbohydratesBites) * row.bites || 0;
        }, 0);
      });
      return total;
    });

    const actualFatsBites = computed(() => {
      let total = 0;
      props.mealsBySelectedDay.forEach((meal: MenuMealDto) => {
        total += meal.mealDetails.reduce((total: number, row: any) => {
          return total + parseFloat(row.food.fatBites) * row.bites || 0;
        }, 0);
      });
      return total;
    });

    const plannedFoodCalories = computed(() => {
      return props.nutritionalPlan?.currentCalories;
    });

    const actualKcalTotal = computed(() => {
      let total = 0;
      props.mealsBySelectedDay.forEach((meal) => {
        meal.mealDetails.forEach((mealDetail: MenuMealDetailDto) => {
          const { carbohydratesBites, proteinBites, fatBites } = mealDetail.food;
          total += (carbohydratesBites * 4 + proteinBites * 4 + fatBites * 9) * 10 * mealDetail.bites;
        });
      });

      return total;
    });

    const onChangeNutritionalPlan = ({ value }: DropdownChangeEvent) => {
      emit('on-update-nutritional-plan', value);
    };

    const updateEditMode = () => {
      emit('on-update-edit-mode');
    };

    return {
      updateEditMode,
      onChangeNutritionalPlan,
      actualCarbohydratesBites,
      actualFatsBites,
      actualKcalTotal,
      actualProteinBites,
      plannedCarbohydratesBites,
      plannedFatsBites,
      plannedProteinBites,
      plannedFoodCalories,
      nutritionalPlanId,
    };
  },
});
