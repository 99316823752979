import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "p-grid--wearables-nutrition-row" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "card"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_NutritionalPlanMenuCard = _resolveComponent("NutritionalPlanMenuCard")!
  const _component_MealFoodsCard = _resolveComponent("MealFoodsCard")!
  const _component_Button = _resolveComponent("Button")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_MenuDayConfirmModal = _resolveComponent("MenuDayConfirmModal")!
  const _component_MenuCreateFormModal = _resolveComponent("MenuCreateFormModal")!
  const _component_AlternativeMealModal = _resolveComponent("AlternativeMealModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loadingMenuDay)
        ? (_openBlock(), _createBlock(_component_LoadingComponent, {
            key: 0,
            "spinner-color": "lavender-700"
          }))
        : (_ctx.menuDay && _ctx.menuDay.nutritionalPlanId)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.diet)
                ? (_openBlock(), _createBlock(_component_NutritionalPlanMenuCard, {
                    key: 0,
                    diet: _ctx.diet,
                    nutritionalPlanId: _ctx.menuDay.nutritionalPlanId,
                    mealsBySelectedDay: _ctx.mealsBySelectedDay,
                    date: _ctx.date,
                    onOnEditDietDay: _ctx.onEditDietDay
                  }, null, 8, ["diet", "nutritionalPlanId", "mealsBySelectedDay", "date", "onOnEditDietDay"]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mealsBySelectedDay, (meal) => {
                return (_openBlock(), _createBlock(_component_MealFoodsCard, {
                  key: meal.name,
                  nutritionalPlan: _ctx.menuDay.nutritionalPlan,
                  mealMenu: meal,
                  userCanEdit: true,
                  "show-completed-meal-tag": true,
                  onOnUpdateMenuFood: _ctx.onMenuFoodUpdate,
                  onOnCreateAlternative: _ctx.onCreateAlternativeMeal
                }, null, 8, ["nutritionalPlan", "mealMenu", "onOnUpdateMenuFood", "onOnCreateAlternative"]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_EmptyState, {
                heading: _ctx.$t('nutrition.menu.empty-state.title'),
                text: _ctx.$t('nutrition.menu.empty-state.subtitle'),
                secondary: false,
                icon: "icon-file_blank_outline"
              }, {
                bottom: _withCtx(() => [
                  _createVNode(_component_Button, {
                    class: "p-button p-button-icon",
                    onClick: _ctx.onNewMenu
                  }, {
                    default: _withCtx(() => [
                      _cache[0] || (_cache[0] = _createElementVNode("i", {
                        class: "icon-plus",
                        "aria-hidden": "true"
                      }, null, -1)),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('nutrition.menu.new')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }, 8, ["heading", "text"])
            ]))
    ]),
    _createVNode(_component_MenuDayConfirmModal, {
      ref: "updateNutritionPlanModalRef",
      date: _ctx.date,
      onUpdateMenu: _ctx.onUpdateMenu
    }, null, 8, ["date", "onUpdateMenu"]),
    _createVNode(_component_MenuCreateFormModal, {
      ref: "menuCreateModalRef",
      date: _ctx.date,
      onNewMenu: _ctx.fetchMenuDay
    }, null, 8, ["date", "onNewMenu"]),
    _createVNode(_component_AlternativeMealModal, {
      ref: "alternativeMealModalRef",
      patientId: _ctx.patientId,
      onCreatedAlternativeMeal: _ctx.onUpdateMenu
    }, null, 8, ["patientId", "onCreatedAlternativeMeal"])
  ], 64))
}