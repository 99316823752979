import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "p-grid--wearables-nutrition-row"
}
const _hoisted_2 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_MealChart = _resolveComponent("MealChart")!
  const _component_EventsList = _resolveComponent("EventsList")!

  return (_ctx.loadingEvents)
    ? (_openBlock(), _createBlock(_component_LoadingComponent, {
        key: 0,
        "spinner-color": "lavender-700"
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_MealChart, {
            chartStart: _ctx.selectedStartDate,
            chartEnd: _ctx.selectedEndDate,
            title: _ctx.$t('wearables.activityChart.title'),
            events: _ctx.events,
            "glucose-statistics": _ctx.glucoseStatistics,
            "heart-rate-statistics": _ctx.heartRateStatistics
          }, null, 8, ["chartStart", "chartEnd", "title", "events", "glucose-statistics", "heart-rate-statistics"])
        ]),
        _createVNode(_component_EventsList, { events: _ctx.events }, null, 8, ["events"])
      ]))
}