import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "p-grid--wearables-nutrition-row" }
const _hoisted_2 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_EventListItem = _resolveComponent("EventListItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_HeadingComponent, {
        title: _ctx.$t('wearables.nutritionTab.meal-events-during-day'),
        "sub-heading": true,
        divider: false
      }, null, 8, ["title"]),
      (_ctx.events.length === 0)
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 0,
            icon: "icon-confused",
            text: _ctx.$t('wearables.sleepChart.noData'),
            textAtBottom: true
          }, null, 8, ["text"]))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.events, (event, index) => {
            return (_openBlock(), _createBlock(_component_EventListItem, {
              key: index,
              event: event,
              onOnEventClick: _ctx.handleEventClick
            }, null, 8, ["event", "onOnEventClick"]))
          }), 128))
    ])
  ]))
}