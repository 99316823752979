
import EmptyState from '@/components/EmptyState.vue';
import { Ref, computed, defineComponent, onMounted, ref, watch } from 'vue';
import { Diet, DietDayFormDto, MenuDay, MenuMealDto, updateMenuMealFromMenuMealDto } from '@/models/NutritionalPlan';
import { nutritionalPlanService } from '@/services/NutritionalPlanService';
import { useRoute } from 'vue-router';
import { ServiceError } from '@/services/util/ServiceError';
import moment from 'moment-timezone';
import LoadingComponent from '@/components/LoadingComponent.vue';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import MealFoodsCard from '@/components/nutrition/menus/MealFoodsCard/MealFoodsCard.vue';
import NutritionalPlanMenuCard from '@/components/nutrition/menus/NutritionalPlanMenuCard.vue';
import MenuCreateFormModal from '@/components/nutrition/menus/MenuCreateFormModal.vue';
import MenuDayConfirmModal from './MenuDayConfirmModal.vue';
import AlternativeMealModal from '@/components/nutrition/menus/AlternativeMealModal.vue';
export default defineComponent({
  components: {
    EmptyState,
    MealFoodsCard,
    LoadingComponent,
    NutritionalPlanMenuCard,
    MenuCreateFormModal,
    MenuDayConfirmModal,
    AlternativeMealModal,
  },
  emits: ['on-child-have-menu'],
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const toast = useToast();
    const { t } = useI18n();
    const updateNutritionPlanModalRef = ref();
    const route = useRoute();
    const menuCreateModalRef = ref();
    const alternativeMealModalRef = ref();
    const loadingMenuDay = ref(false);
    const menuDay: Ref<MenuDay | undefined> = ref();
    const diet: Ref<Diet | undefined> = ref();
    const patientId = route.params.patientId as string;
    const mealsBySelectedDay = computed(() => {
      if (props.date === null && menuDay.value === null) {
        return [];
      }

      return menuDay.value?.meals ?? [];
    });

    const fetchDiet = async () => {
      const result = await nutritionalPlanService.findDiet(patientId);

      if (!(result instanceof ServiceError)) {
        diet.value = result;
      }
    };

    const onUpdateMenu = async () => {
      loadingMenuDay.value = true;
      await fetchMenuDay();
      await fetchDiet();
      loadingMenuDay.value = false;
    };

    const onNewMenu = async () => {
      menuCreateModalRef.value?.openDialog();
    };

    const fetchMenuDay = async () => {
      if (!props.date) {
        return;
      }
      loadingMenuDay.value = true;

      const result = await nutritionalPlanService.findMenuDay(
        patientId,
        moment(props.date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      );

      if (!(result instanceof ServiceError)) {
        menuDay.value = result;
      } else {
        menuDay.value = undefined;
      }

      if (menuDay.value?.nutritionalPlanId) {
        emit('on-child-have-menu', true);
      } else {
        emit('on-child-have-menu', false);
      }

      loadingMenuDay.value = false;
    };

    async function onMenuFoodUpdate(updatedMenuMeal: MenuMealDto) {
      const result = await nutritionalPlanService.updateMenuDayMeal(
        patientId,
        updatedMenuMeal.id,
        props.date,
        updateMenuMealFromMenuMealDto(updatedMenuMeal.mealDetails),
      );

      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorEditMeal')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        menuDay.value = result;
        toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditMeal')}`, life: 3000 });
      }
    }

    const onEditDietDay = async (dietFormDto: DietDayFormDto, diet: Diet) => {
      updateNutritionPlanModalRef.value?.openDialog(dietFormDto, diet);
    };

    onMounted(async () => {
      if (props.date) {
        await fetchMenuDay();
        await fetchDiet();
      }
    });

    watch(
      () => props.date,
      async () => {
        await fetchMenuDay();
        await fetchDiet();
      },
    );

    const onCreateAlternativeMeal = async (mealId: string, nameMeal: string) => {
      alternativeMealModalRef.value?.openDialog(mealId, props.date, undefined, nameMeal);
    };

    return {
      menuCreateModalRef,
      updateNutritionPlanModalRef,
      alternativeMealModalRef,
      loadingMenuDay,
      menuDay,
      diet,
      patientId,
      mealsBySelectedDay,

      fetchDiet,
      onEditDietDay,
      onNewMenu,
      fetchMenuDay,
      onUpdateMenu,
      onMenuFoodUpdate,
      onCreateAlternativeMeal,
    };
  },
});
