
import scoreHelper from '@/helpers/Score.helper';
import { EventScoreDto } from '@/models/Event';
import { ScoreType } from '@/models/Score';
import { eventService } from '@/services/EventService';
import { ServiceError } from '@/services/util/ServiceError';
import { PropType, defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import ScoreCircular from '@/components/charts/ScoreCircular.vue';

export default defineComponent({
  components: { ScoreCircular },
  emits: ['on-event-click'],
  props: {
    event: {
      type: Object as PropType<EventScoreDto>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const iconEventUrl = ref(window.location.origin + '/assets/icon/wearables/meal.svg');
    const route = useRoute();
    const patientId = route.params.patientId as string;
    const efficiency = ref();
    const minGlucose = ref();
    const maxGlucose = ref();
    const avgGlucose = ref();
    const coefficientOfVariation = ref();
    const timeInRange = ref();

    const fetchStatistics = async () => {
      if (!props.event) {
        return;
      }
      const result = await eventService.findStatistics(props.event?.id, patientId);
      if (!(result instanceof ServiceError)) {
        efficiency.value = result.statistics.scores.find((item) => item.type == ScoreType.EFFICIENCY);
        minGlucose.value = result.statistics.scores.find((item) => item.type == ScoreType.MIN_GLUCOSE);
        maxGlucose.value = result.statistics.scores.find((item) => item.type == ScoreType.MAX_GLUCOSE);
        avgGlucose.value = result.statistics.scores.find((item) => item.type == ScoreType.AVG_GLUCOSE);
        timeInRange.value = result.statistics.scores.find((item) => item.type == ScoreType.TIME_IN_RANGE);
        coefficientOfVariation.value = result.statistics.scores.find(
          (item) => item.type == ScoreType.COEFFICIENT_OF_VARIATION,
        );
      }
    };

    function buildInfoItems() {
      return [
        { key: 'glc-min', value: minGlucose.value?.value ? minGlucose.value?.value + ' mg' : '-' },
        { key: 'glc-max', value: maxGlucose.value?.value ? maxGlucose.value?.value + ' mg' : '-' },
        { key: 'glc-avg', value: avgGlucose.value?.value ? avgGlucose.value?.value + ' mg' : '-' },
        {
          key: 'variation',
          value: coefficientOfVariation.value?.value ? coefficientOfVariation.value?.value + ' %' : '-',
        },
        { key: 'in-range', value: timeInRange.value?.value ? timeInRange.value?.value + ' %' : '-' },
      ];
    }

    const handleCardClick = () => {
      emit('on-event-click', props.event?.id);
    };

    onMounted(() => {
      fetchStatistics();
    });

    return {
      iconEventUrl,
      efficiency,
      minGlucose,
      maxGlucose,
      coefficientOfVariation,
      avgGlucose,
      timeInRange,
      scoreHelper,
      buildInfoItems,
      handleCardClick,
    };
  },
});
