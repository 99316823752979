import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NutritionalPlanMenuCardBase = _resolveComponent("NutritionalPlanMenuCardBase")!

  return (_openBlock(), _createBlock(_component_NutritionalPlanMenuCardBase, {
    editMode: _ctx.editMode,
    waitSubmit: _ctx.waitSubmit,
    onSubmit: _ctx.onSubmit,
    onCancelEdition: _ctx.onCancelEdition,
    totalMeals: _ctx.nutritionalPlan?.meals?.length,
    nutritionalPlan: _ctx.nutritionalPlan,
    nutritionalPlanOptions: _ctx.nutritionalPlanOptions,
    userCanEditDiet: _ctx.userCanEditDiet,
    showValidation: _ctx.showValidation,
    dietFormDto: _ctx.dietFormDto,
    validatedDietDayForm: _ctx.validatedDietDayForm,
    mealsBySelectedDay: _ctx.mealsBySelectedDay,
    onOnUpdateEditMode: _ctx.onEditMenuDay,
    onOnUpdateNutritionalPlan: _ctx.onUpdateNutritionalPlan
  }, null, 8, ["editMode", "waitSubmit", "onSubmit", "onCancelEdition", "totalMeals", "nutritionalPlan", "nutritionalPlanOptions", "userCanEditDiet", "showValidation", "dietFormDto", "validatedDietDayForm", "mealsBySelectedDay", "onOnUpdateEditMode", "onOnUpdateNutritionalPlan"]))
}