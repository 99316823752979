
import { computed, defineComponent, onMounted, ref, Ref } from 'vue';
import { Diet, DietDay, DietDayFormDto } from '@/models/NutritionalPlan';
import { nutritionalPlanService } from '@/services/NutritionalPlanService';
import { ServiceError } from '@/services/util/ServiceError';
import { useRoute } from 'vue-router';
import { isUpdateDietDayFormValid, updateDietDayForm } from '@/validation/nutritionalPlanForm';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  emits: ['new-menu'],
  props: {
    daySelectedIndex: {
      type: Number,
      required: false,
    },
    date: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const route = useRoute();
    const showDialog = ref(false);
    const submitting = ref(false);
    const showValidation = ref(false);
    const dietFormDto: Ref<DietDayFormDto> = ref(new DietDayFormDto(''));
    const nutritionalPlanOptions: Ref<{ label: string; value: string }[]> = ref([]);
    const validatedDietDayForm = computed(() => updateDietDayForm(dietFormDto.value));
    const valid = computed(() => isUpdateDietDayFormValid(validatedDietDayForm.value));
    const patientId = route.params.patientId as string;
    const toast = useToast();
    const { t } = useI18n();
    const dietDay: Ref<DietDay | undefined> = ref();

    const openDialog = (diet: Diet) => {
      showDialog.value = true;
      if (props.daySelectedIndex !== undefined && props.daySelectedIndex !== null) {
        dietDay.value = diet.dietDays.find((dietDay) => dietDay.day === (props.daySelectedIndex ?? 0) + 1);
      }
    };

    const onSubmit = async (e: Event) => {
      e.preventDefault();

      showValidation.value = true;
      if (!valid.value) {
        return;
      }

      try {
        submitting.value = true;
        if (dietFormDto.value && dietDay.value) {
          await updateDietDay();
        } else {
          await createMenuDay();
        }
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorAddMenu')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } finally {
        submitting.value = false;
        showDialog.value = false;
      }
    };

    const updateDietDay = async () => {
      if (!dietDay?.value || props.daySelectedIndex == null) {
        return;
      }

      const dietDayUpdated = await nutritionalPlanService.updateDietDay(
        patientId,
        dietDay.value.day,
        dietFormDto.value,
      );

      if (!(dietDayUpdated instanceof ServiceError)) {
        dietDay.value = dietDayUpdated.dietDays.find((dietDay) => dietDay.day === (props.daySelectedIndex ?? 0) + 1);
        dietFormDto.value = new DietDayFormDto(dietDay.value?.nutritionalPlanId as string);
        emit('new-menu');
      } else {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorEditMenu')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      }
    };

    const createMenuDay = async () => {
      if (!props.date) {
        return;
      }

      const dietDayUpdated = await nutritionalPlanService.createMenuDay(patientId, props.date, dietFormDto.value);
      if (!(dietDayUpdated instanceof ServiceError)) {
        emit('new-menu');
      } else {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorEditMenu')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      }
    };

    const fetchNutritionPlanOptions = async () => {
      if (nutritionalPlanOptions.value.length > 0) {
        return;
      }
      const result = await nutritionalPlanService.findAll(patientId);
      if (!(result instanceof ServiceError)) {
        nutritionalPlanOptions.value = result
          .filter((plan) => plan.enabled)
          .map((plan) => ({ label: plan.name, value: plan.id }));
      }
    };

    onMounted(() => {
      fetchNutritionPlanOptions();
    });

    return {
      submitting,
      showDialog,
      dietFormDto,
      openDialog,
      onSubmit,
      nutritionalPlanOptions,
      showValidation,
      validatedDietDayForm,
    };
  },
});
