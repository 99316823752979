
import { computed, defineComponent, ref, Ref } from 'vue';
import { Diet, MenuDayCreateFormDto } from '@/models/NutritionalPlan';
import { useRoute } from 'vue-router';
import { createMenuDayForm, isCreateMenuDayFormValid } from '@/validation/nutritionalPlanForm';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { nutritionalPlanService } from '@/services/NutritionalPlanService';
import { ServiceError } from '@/services/util/ServiceError';
import moment from 'moment-timezone';

export default defineComponent({
  emits: ['new-menu-submit'],
  setup(_props, { emit }) {
    const route = useRoute();
    const showDialog = ref(false);
    const submitting = ref(false);
    const showValidation = ref(false);
    const menuDayFormDto: Ref<MenuDayCreateFormDto> = ref({} as MenuDayCreateFormDto);
    const validatedMenuDayForm = computed(() => createMenuDayForm(menuDayFormDto.value));
    const valid = computed(() => isCreateMenuDayFormValid(validatedMenuDayForm.value));
    const patientId = route.params.patientId as string;
    const weeksOptions: Ref<{ label: string; value: string }[]> = ref(
      Array.from({ length: 12 }, (_, index) => ({
        label: `${index + 1}`,
        value: `${index + 1}`,
      })),
    );
    const minRangeDate = ref(new Date().getFullYear());
    const maxRangeDate = ref(new Date().getFullYear() + 1);

    const toast = useToast();
    const { t } = useI18n();
    const dietDay: Ref<Diet> = ref({} as Diet);

    const openDialog = (diet: Diet) => {
      showDialog.value = true;
      dietDay.value = diet;
    };

    const onSubmit = async (e: Event) => {
      e.preventDefault();

      showValidation.value = true;
      if (!valid.value) {
        return;
      }

      if (menuDayFormDto.value) {
        try {
          submitting.value = true;
          emit('new-menu-submit', true);
          const { startDate, weeks } = menuDayFormDto.value;
          const endDate = moment(startDate).add(weeks, 'weeks');

          const menuDayCreated = await nutritionalPlanService.createMenuPlanification(
            patientId,
            moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            endDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          );
          if (!(menuDayCreated instanceof ServiceError)) {
            emit('new-menu-submit', false);
          } else {
            toast.add({
              severity: 'error',
              summary: `${t('messages.notifications.errorEditMenu')} ${t('messages.notifications.tryLater')}`,
              life: 3000,
            });
          }
        } catch (error) {
          toast.add({
            severity: 'error',
            summary: `${t('messages.notifications.errorAddMenu')} ${t('messages.notifications.tryLater')}`,
            life: 3000,
          });
        } finally {
          submitting.value = false;
          showDialog.value = false;
        }
      }
    };

    return {
      submitting,
      showDialog,
      menuDayFormDto,
      openDialog,
      onSubmit,
      showValidation,
      weeksOptions,
      validatedMenuDayForm,
      minRangeDate,
      maxRangeDate,
    };
  },
});
